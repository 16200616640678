import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import { Layout, PostCard, Pagination } from '../components/common'
import { MetaData } from '../components/common/meta'
import { StaticImage, getImage } from 'gatsby-plugin-image'

/**
* Main index page (home page)
*
* Loads all posts from Ghost and uses pagination to navigate through them.
* The number of posts that should appear per page can be setup
* in /utils/siteConfig.js under `postsPerPage`.
*
*/
const Index = ({ data, location, settings, pageContext }) => {
    const posts = data.allGhostPost.edges
    const site = data.allGhostSettings.edges[0].node

    return (
        <>
            <MetaData location={location} />

            <Layout isHome={true} bodyClass="home-template">

                <div className="site-header-content">
                    {!!site.cover_image &&
                        <img className="site-header-cover"
                            srcSet={`${site.cover_image} 300w,
                                    ${site.cover_image} 600w,
                                    ${site.cover_image} 1000w,
                                    ${site.cover_image} 2000w`}
                            sizes="100vw"
                            src={site.logo}
                            alt={site.title}
                        />
                    }

                    <h1 className="site-title">
                        {!!site.logo ?
                            <img className="site-logo" src={site.logo} alt={site.title} />
                            :
                            site.title
                        }
                    </h1>
                    <p>{site.description}</p>
                </div>

                {/* The main content area */}
                <main id="site-main" className="site-main outer">
                    <div className="inner posts">

                        <div className="post-feed">
                            {posts.map(({ node }, index) => (
                                // The tag below includes the markup for each post - components/common/PostCard.js
                                <PostCard key={node.id} post={node} index={index} home={true} />
                            ))}
                        </div>

                    </div>

                    <Pagination pageContext={pageContext} />
                    
                </main>
            </Layout>
        </>
    )
}

Index.propTypes = {
    data: PropTypes.shape({
        allGhostPost: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    pageContext: PropTypes.object,
}

export default Index

// This page query loads all posts sorted descending by published date
// The `limit` and `skip` values are used for pagination
export const pageQuery = graphql`
  query GhostPostQuery($limit: Int!, $skip: Int!) {
    allGhostSettings {
        edges {
            node {
                ...GhostSettingsFields
            }
        }
    }
    
    allGhostPost(
        sort: { order: DESC, fields: [published_at] },
        limit: $limit,
        skip: $skip
    ) {
      edges {
        node {
          ...GhostPostFields
        }
      }
    }
  }
`
